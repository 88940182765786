export const categories = [
  'All',
  'Healthtech & wellness',
  'Telecommunications & IT',
  'AI & machine learning',
  'Proptech & real estate',
  'Fintech & financial services',
  'Web3',
  'Sustainability & cleantech',
  'Data & analytics',
  'Legal & professional services',
  'Mobility, transportation & logistics',
  'Hardware, robotics & IoT',
  'SaaS',
  'Gaming, VR & AR',
  'Energy & utilities',
  'Agritech & foodtech',
  'Industrials, manufacturing & consumer goods',
  'Advertising, content & marketing',
  'Security',
  'HR & recruitment',
  'E-commerce & retail',
  'Entertainment & media',
  'Travel & hospitality',
  'Social media & networking',
  'Education',
  'Event management',
  'Lifestyle & fashion',
  'Politics, government & international trade',
  'Charities & NGOs',
  'Sports & fitness',
  'Design',
  'Venture capital & private equity',
];
